import { IDashOrg, IDashOrgUser, IRLSTag, IReportData } from '../shared/interfaces';
import React, {useCallback, useEffect, useState} from 'react';

import AddUser from './AddUser';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DemoteIcon from '@mui/icons-material/ArrowCircleDown'
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import OrgSelector from './OrgSelector';
import { Page } from './layout/Page';
import PromoteIcon from '@mui/icons-material/ArrowCircleUp'
import TextField from '@mui/material/TextField';

const formStyle = {

  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};
export type Props = {
  dashboard: IReportData,
  handleClose: () => void,
  action: (dash: IReportData, action: string, newDash:boolean) => void
};
type orgListItem = {
  value:number,
  label:string
};
function Dashboard(props: Props) {

  const dummyOptions:orgListItem = { 
      value:-1,
      label: ""
    };

  const [editDashId, setEditDashId] = React.useState('');
  const [editDashName, setEditDashName] = React.useState('');
  const [editDashType, setEditDashType] = React.useState('');
  const [editDashAccount, setEditDashAccount] = React.useState('');
  const [editImageFile, setEditImageFile] = React.useState('');
  const [editQTopicId, setEditQTopicId] = React.useState('');
  const [editDashRLS, setEditDashRLS] = React.useState(props.dashboard.RLS ? true : false);
  const [editDashS3TagSource, setEditDashS3TagSource] = React.useState('');
  const [editParams, setEditParams] = React.useState<string[]>([]);
  const [editRLSKeys, setEditRLSKeys] = React.useState<string[]>([]);
  const [editRLSTags, setEditRLSTags] = React.useState<IRLSTag[]>([]);
  const [dashboard, setDashboard] = useState(props.dashboard);
  const [orgDisplay, setOrgDisplay] = useState(<div key="List-0">Organisations:</div>)
  const [orgList, setOrgList] = useState<orgListItem[]>([dummyOptions]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('-1');
  const [target, setTarget] = useState('org');
  const [userDeleteOrg, setUserDeleteOrg] = useState('-1');
  const [targetName, setTargetName] = useState('');

  const editing = props.dashboard.DashboardId.length > 0;

  const deleteTarget = () => {

    if(target === "org") {
      let _orgs:any = []

      if(dashboard.OrgData) {
        _orgs = dashboard.OrgData;

        _orgs = _orgs.filter((_org:any) => {
          return _org.OrgId !== parseInt(deleteId);
        });
        
        if(_orgs.length > 0 )
        {
          dashboard.OrgData = _orgs;
          dashboardChanged();
        }
      } else {
        if(dashboard.Organisations) {
          _orgs = dashboard.Organisations;

          _orgs = _orgs.filter((_org:any) =>{
            return _org[0].OrgId !== parseInt(deleteId);
          });

          if(_orgs.length > 0 )
          {
            dashboard.Organisations = _orgs;
            dashboardChanged();
          }
        }
      }
    }

    if(target === "user") {
      let _users:any = []
      
      let isSuperUser = false;

      if(dashboard.OrgData) {

        const org = dashboard.OrgData.filter((o:any) => {
          return o.OrgId === parseInt(userDeleteOrg);
        })[0];

        const otherOrgs = dashboard.OrgData.filter((o:any) => {
          return o.OrgId !== parseInt(userDeleteOrg);
        })

        _users = org.OrgUsers.filter((_user:any) => {
          return _user.UserId !== parseInt(deleteId);
        });

        if(_users.length === org.OrgUsers.length) {
          const _superList = org.OrgSuperUsers ? org.OrgSuperUsers : [];
          _users = _superList.filter((user:any)=>{
            return user.UserId !== parseInt(deleteId);
          })
          isSuperUser = true;
        }

        if(_users.length > 0 || isSuperUser)
        {
          if(isSuperUser) {
            org.OrgSuperUsers = _users
          } else {
            org.OrgUsers = _users
          }

          otherOrgs.push(org)

          dashboard.OrgData = otherOrgs;
          dashboardChanged();
        }
      } else {
        if(dashboard.Organisations) {
          const org:any = dashboard.Organisations.filter((o:any)=>{
            return o[0].OrgId === parseInt(userDeleteOrg);
          })[0];

          const otherOrgs = dashboard.Organisations.filter((o:any) =>{
            return o[0].OrgId !== parseInt(userDeleteOrg);
          });

          _users = org[1].filter((_user:any) => {
            return _user.UserId !== parseInt(deleteId);
          });

          if(_users.length === org[1].length) {
            const _superList = org[2] ? org[2] : [];
            _users = _superList.filter((user:any)=>{
              return user.UserId !== parseInt(deleteId);
            });
            isSuperUser = true;
          }

          if(_users.length > 0 || isSuperUser)
          {
            org[1] = _users;

            otherOrgs.push(org);

            dashboard.Organisations = otherOrgs;
            dashboardChanged();
          }
        }
      }

    }

  }

  const orgListItem = useCallback((orgId: string, orgName: string) => {

    const handleDelete = (e: any, orgId: string) => {
      e.stopPropagation();

      setTarget("org");
      setDeleteId(orgId);
      setTargetName(orgName);
      setIsOpen(true);
    }

    const itemText = orgName ? orgName + " (" + orgId + ")" : "Org Not Found (" + orgId + ")"

    return (
      <ListItem key={"Org - " + orgId} secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={(e) => { handleDelete(e, orgId) }}>
            <DeleteIcon />
          </IconButton>
        }>
        <ListItemText
        primary={itemText}
        />
        </ListItem>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dashboard])

  const userListItem = useCallback((orgId: string, userId: string, userName: string, onlyUser = false, superUser: boolean = false) => {

    const handleDelete = (e: any, deleteId: string) => {
      e.stopPropagation();

      setTarget("user");
      setDeleteId(deleteId);
      setTargetName(userName);
      setUserDeleteOrg(orgId);
      setIsOpen(true);
    }

    const toggleUserType = (e: any, promoteId: string) => {
      e.stopPropagation();

      let _user:any = null;
      
      if(dashboard.OrgData) {

        const org = dashboard.OrgData.filter((o:any) => {
          return o.OrgId === parseInt(orgId);
        })[0];

        const otherOrgs = dashboard.OrgData.filter((o:any) => {
          return o.OrgId !== parseInt(orgId);
        })

        const superUsers = org.OrgSuperUsers ? org.OrgSuperUsers : [];

        const _originatingList = superUser ? superUsers : org.OrgUsers;
        const _targetList = superUser ? org.OrgUsers : superUsers;

        _user = _originatingList.filter((_user:any) => {
          return _user.UserId === parseInt(promoteId);
        })[0];

        const _newOriginatingList = _originatingList.filter((_user:any) => {
          return _user.UserId !== parseInt(promoteId);
        })

        _targetList.push(_user);

        if(superUser || _newOriginatingList.length > 0)
        {
          org.OrgUsers = superUser ? _targetList : _newOriginatingList;
          org.OrgSuperUsers = superUser ? (_newOriginatingList.length > 0 ?_newOriginatingList : []) : _targetList;
            
          otherOrgs.push(org)

          dashboard.OrgData = otherOrgs;
          dashboardChanged();
        }
      } else {
        if(dashboard.Organisations) {
          const org:any = dashboard.Organisations.filter((o:any)=>{
            return o[0].OrgId === parseInt(orgId);
          })[0];

          const otherOrgs = dashboard.Organisations.filter((o:any) =>{
            return o[0].OrgId !== parseInt(orgId);
          });

          const superUsers = org[2] ? org[2] : []

          const _originatingList = superUser ? superUsers : org[1];
          const _targetList = superUser ? org[1] : superUsers;

          _user = _originatingList.filter((_user:any) => {
            return _user.UserId !== parseInt(promoteId);
          });

          const _newOriginatingList = _originatingList.filter((_user:any) => {
            return _user.UserId !== parseInt(promoteId);
          })

          _targetList.push(_user);

          if(_newOriginatingList.length > 0 )
            {
              org[1] = superUser ? _targetList : _newOriginatingList;
              org[2] = superUser ? _newOriginatingList: _targetList;
                
              otherOrgs.push(org)
    
              dashboard.Organisations = otherOrgs;
              dashboardChanged();
            }
        }
      }

    }

    const superUserMark = superUser ? "[S] " : "";
    const userNameText = userName ? userName : "User Not Found";

    const itemText = superUserMark + userNameText + " (" + userId + ")";

    const promotionIcon = superUser ? <DemoteIcon/> : <PromoteIcon/>;

    const promotionLabel = superUser ? "Demote user" : "Promote user";

    const promoteButton = onlyUser ? "" : <IconButton edge="end" aria-label={promotionLabel} className="tw-pr-2" onClick={(e) => { toggleUserType(e, userId) }}>
                            {promotionIcon}
                          </IconButton>

    return (
      <ListItem key={"User-" + userId + "-" + orgId} className="tw-pl-4" secondaryAction={
        <div>
          {promoteButton}
          <IconButton edge="end" aria-label="delete" className="tw-pr-6" onClick={(e) => { handleDelete(e, userId) }}>
            <DeleteIcon />
          </IconButton>
        </div>
        }>
        <ListItemText
        primary={itemText}
        />
        </ListItem>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dashboard])

  const orgUsersUpdated = (orgId: string, dashUsers: any) => {
    if(dashboard.OrgData) {

      const org = dashboard.OrgData.filter((o:any) => {
        return o.OrgId === parseInt(orgId);
      })[0];

      const otherOrgs = dashboard.OrgData.filter((o:any) => {
        return o.OrgId !== parseInt(orgId);
      })

      const uniqueUsers = org.OrgUsers.concat(dashUsers).sort((a, b)=>{ return a.UserId - b.UserId}).filter((item, pos, self) => {
        return !pos || item.UserId !== self[pos -1].UserId;
      });

      org.OrgUsers = uniqueUsers;
    
      otherOrgs.push(org)

      dashboard.OrgData = otherOrgs;
      dashboardChanged();
      
    } 
  }

  const ReportOrgDisplay:any = useCallback((orgs: any[], orgData: boolean = false) => {

    let rtnOrgs:Array<JSX.Element> = [];

    orgs.forEach((org) => {

      let _orgId: string = orgData ? org.OrgId : org[0].OrgId;
      let _orgName: string = orgData ? org.OrgName : org[0].OrgName;
      let _orgUsers: any = orgData ? org.OrgUsers : org[1];
      let _superUserData: any = [];

      if(orgData ? org.OrgSuperUsers : org[2] != null) {
        _superUserData = orgData ? org.OrgSuperUsers : org[2];
      }

      let _orgSuperUsers: any = _superUserData;

      const orgNameStr: string = _orgName ? _orgName : "Org Not Found";

      rtnOrgs.push(orgListItem(_orgId, orgNameStr));

      if(typeof _orgUsers !== "undefined" && _orgUsers.length > 0) {
          _orgUsers.forEach((orgUser:any, idx:any) =>{
            const orgUserData: IDashOrgUser = {
              UserId: orgUser.UserId,
              Email: orgUser.Email
            };
            rtnOrgs.push(userListItem(_orgId, orgUserData.UserId.toString(), orgUserData.Email ? orgUserData.Email : "User Email Not Found", _orgUsers.length === 1));
          });
        };

        if(typeof _orgSuperUsers !== "undefined" && _orgSuperUsers.length > 0) {
          _orgSuperUsers.forEach((orgSuperUser:any, idx:any) =>{
            if(typeof orgSuperUser !== "undefined"){
              const orgSuperUserData: IDashOrgUser = {
                UserId: orgSuperUser.UserId,
                Email: orgSuperUser.Email
              };
              rtnOrgs.push(userListItem(_orgId, orgSuperUserData.UserId.toString(), orgSuperUserData.Email ? orgSuperUserData.Email : "User Email Not Found", false, true));
            }
          });
        };

      rtnOrgs.push(<AddUser orgId={parseInt(_orgId)} usersUpdated={orgUsersUpdated}/>)
    });

    return (
      <div>
        {rtnOrgs}
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgListItem, userListItem])

  const dashboardChanged = useCallback(() => {
    let _orgs:any = []
    let orgData = false;

    if(dashboard.OrgData) {
      _orgs = dashboard.OrgData;
      orgData = true;
    } else {
      if(dashboard.Organisations) {
        _orgs = dashboard.Organisations;
      }
    }

    const displayOrgs = ReportOrgDisplay(_orgs, orgData)

    setOrgDisplay(displayOrgs);
    setOrgList(orgList);
  }, [dashboard.Organisations, dashboard.OrgData, orgList, ReportOrgDisplay])

  useEffect(() => {
    if(typeof dashboard !== "undefined"){
      dashboardChanged();
    }
  }, [dashboard, dashboardChanged, orgList]);
  
  const edited = () => {

    const _orgData:any = []

    if(!dashboard.OrgData && dashboard.Organisations) {

      dashboard.Organisations.forEach((org:any) => {
         const orgId: number = org[0] ? org[0].OrgId : 0;
         const orgName: string = org[0] ? org[0].OrgName : '';
         const orgUsers: any = org[1] ? org[1] : [];
         const orgSuperUsers: any = org[2] ? org[2] : [];

         if(orgId > 0) {

          const orgObj:IDashOrg = {
            OrgId: orgId,
            OrgName: orgName,
            OrgUsers: orgUsers,
            OrgSuperUsers: orgSuperUsers
          }

          _orgData.push(orgObj)
        }
        
      });
    }

    const dash:IReportData = {
      DashboardId: editDashId !== '' ? editDashId : dashboard.DashboardId,
      ReportName: editDashName !== '' ? editDashName : dashboard.ReportName,
      ReportType: editDashType !== '' ? editDashType : dashboard.ReportType,
      ReportAccount: editDashAccount !== '' ? editDashAccount : dashboard.ReportAccount,
      Parameters: editParams.length > 0 ? editParams : dashboard.Parameters, 
      RLSKeys: editRLSKeys.length > 0 ? editRLSKeys : dashboard.RLSKeys,
      RLSTags: editRLSTags.length > 0 ? editRLSTags : dashboard.RLSTags,
      OrgData: dashboard.OrgData ? dashboard.OrgData : _orgData, 
      ImageFile: editImageFile !== '' ? editImageFile: dashboard.ImageFile ? dashboard.ImageFile : '',
      QTopicId: editQTopicId !== '' ? editQTopicId: dashboard.QTopicId ? dashboard.QTopicId : '',
      RLS: editDashRLS ? editDashRLS : false,
      S3TagSource: editDashS3TagSource !== '' ? editDashS3TagSource : dashboard.S3TagSource ? dashboard.S3TagSource : ''
    }
    
    setDashboard(dash);

    props.action(dash, "PUT", !editing);
  }

  const editParamsToArray = (params:string) => {

    const paramArray = params.toString().replace(/\s+/g, '').split(',');

    setEditParams(paramArray);
  }

  const editRLSKeysToArray = (RLSKeys:string) => {

    const RLSKeyArray = RLSKeys.toString().replace(/\s+/g, '').split(',');

    setEditRLSKeys(RLSKeyArray);
  }

  const editRLSTagsToArray = (RLSTags:string) => {

    const RLSTagStrings = RLSTags.toString().replace(/\s+/g, '').split(',');

    const RLSTagArray:IRLSTag[] = [];

    RLSTagStrings.forEach((tagString)=>{
      const tagData = tagString.split('=');

      RLSTagArray.push({
        Tag: tagData[0],
        Field: tagData[1]
      })

    })

    setEditRLSTags(RLSTagArray);
  }

  const rlsTagsToString = (rlsTags:IRLSTag[]) => {
    if(rlsTags.length > 0) {
      let rtnString = '';

      rlsTags.forEach((tag:IRLSTag, idx:number) => {
        if(idx > 0) {
          rtnString += ', '
        }
        rtnString += tag.Tag + '=' + tag.Field;
      })

      return rtnString;
    } else {
      return '';
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    
    <Page>
      <div>Attributes marked with (*) are required.</div>
      <Box sx={formStyle}>
        <TextField 
          id="DashId" 
          label="Dashboard ID*"
          defaultValue={dashboard.DashboardId}
          disabled = {editing}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditDashId(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth />
        <TextField 
          id="DashType"
          label="Dashboard Type*"
          defaultValue={dashboard.ReportType}
          disabled = {editing}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditDashType(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth />
        <TextField
          id="DashName"
          label="Dashboard Name*"
          defaultValue={dashboard.ReportName}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditDashName(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth />
        <TextField
          id="DashAccount"
          label="Dashboard Account*"
          defaultValue={dashboard.ReportAccount}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditDashAccount(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <TextField
          id="ImageFile"
          label="Image File Name"
          defaultValue={dashboard.ImageFile}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditImageFile(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <TextField
          id="QTopicId"
          label="Q Topic Id"
          defaultValue={dashboard.QTopicId}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditQTopicId(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <TextField
          id="Params"
          label="Parameters (separate parameter names with ',')"
          defaultValue={dashboard.Parameters?.toString()}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editParamsToArray(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <TextField
          id="RLSKeys"
          label="RLS Keys (separate RLS Key names with ',')"
          defaultValue={dashboard.RLSKeys?.toString()}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editRLSKeysToArray(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <div className='tw-mt-4'>Enter tags in the format [RLS Tag Name]=[RLS Tag Value Field Name] e.g. OwnerId=userId. Allowed values from the context are "organisationId" and "userId".</div>
        <div className='tw-mt-4'>If you are using tag values from an S3 held CSV then the format is [RLS Tag Name]=[Column Name From s3 source] i.e. EmployeeGrade=Grade, to concatenate the values of two CSV fields add a + e.g. EmployeeGradeDept=Grade+Department</div>
        <TextField
          id="RLSTags"
          label="RLS Tags (separate RLS Tags with ','"
          defaultValue={dashboard.RLSTags ? rlsTagsToString(dashboard.RLSTags) : []}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editRLSTagsToArray(event.target.value);
          }}
          sx={{ mt: 2}}
          fullWidth 
        />
        <FormControlLabel
          value="start"
          control={<Checkbox
            key={editDashId + editDashRLS}
            checked={editDashRLS ? editDashRLS : false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEditDashRLS(event.target.checked);
            }}
            />}
          label="RLS"
          labelPlacement="start"
        />
        <div>If the values for your RLS tags are held in a CSV in the S3 Data Source Bucket enter the name of the file in the Data Source Bucket here.</div>
        <TextField
          id="S3TagBucket"
          label="S3 Tag Value Source"
          defaultValue={dashboard.S3TagSource}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEditDashS3TagSource(event.target.value);
          }}
          fullWidth 
        />
        <div className='tw-mt-4'>
          <div> 
            {orgDisplay}
          </div>
            <OrgSelector dashboard={dashboard} setEditDash={setDashboard} dashChanged={dashboardChanged} />
        </div>
        <div>
          <Button variant="contained" onClick={() => edited()} sx={{ mt:4, mx:4, width:90}}>Save</Button>
          <Button variant="outlined" onClick={props.handleClose} sx={{ mt:4, mx:4, width:90}}>Cancel</Button>
        </div>
      </Box>
      <DeleteConfirmationModal open={isOpen} onClose={handleClose} handleDelete={deleteTarget} deleteTarget={deleteId} deleteName={targetName} />
    </Page>
  );
}

export default Dashboard;